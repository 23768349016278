import React, { useEffect, useState } from 'react'
import ContentContainer from 'layout/ContentContainer'
import Layout from 'layout/Layout'
import SEO from 'layout/SEO'

// Bootstrap components
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

// API
import { getUrlParams } from 'apis/Browser'

// Quick and durty xfo test page
const XfoPage = () => {
  const [xfo, setXfo] = useState(null)
  const [xfoId, setXfoId] = useState('1234567890')

  // Fetch xfo data
  useEffect(() => {
    const { id } = getUrlParams()
    setXfoId(id)
    const f = async () => {
      const res = await fetch(
        `https://neoevolutions.s3-us-west-2.amazonaws.com/xfo/${id}.json`
      )
      setXfo(await res.json())
    }
    f()
  }, [])

  // Default content
  let title = 'my first xfo'
  let subtitle = 'subtitle'
  let instructions = 'this is how we do it'
  let imageUrl =
    'https://neoevolutions.s3-us-west-2.amazonaws.com/blog/cb450/thumbnails/slideshow.gif'

  if (xfo) {
    title = xfo.title
    subtitle = xfo.subtitle
    instructions = xfo.instructions
    imageUrl = xfo.imageUrl
  }

  return (
    <Layout>
      <SEO title={title} image={imageUrl} />
      <ContentContainer type='body'>
        {/*  TITLE, IMAGE, INSTRUCTIONS */}
        <Row>
          <Col className='text-center' xs={12}>
            <h1>{title}</h1>
            <small>{xfoId}</small>
          </Col>

          <Col xs={12}>
            {imageUrl && (
              <img
                className='rounded mx-auto d-block '
                src={imageUrl}
                alt='thumbnail'
                width='100%'
              />
            )}
          </Col>

          <Col xs={12}>
            <h3>{subtitle}</h3>
            <p>{instructions}</p>
          </Col>
        </Row>

        {/* NAVIGATION */}
        <Row>
          <Col className='text-center' xs={12}>
            <Button style={{ width: '75%' }}>Go Somewhere</Button>
          </Col>
        </Row>
      </ContentContainer>
    </Layout>
  )
}

export default XfoPage
